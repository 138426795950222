import { useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';
import striptags from 'striptags';
import { useRouter } from 'next/router';

// components
import SearchAutocomplete from './SearchAutocomplete';

// utils
import { globalsSearchDisabled, globalsSearchSelector } from 'utils/selectors/globalsSelectors';
import { useYext } from '../Search/use-yext';
import { useTranslationByKey } from 'utils/hooks/use-translations';

function SearchBarHome() {
  const searchConfig = useSelector(globalsSearchSelector);
  const searchPlaceholder = useTranslationByKey('web20_search_placeholder');
  const yextUrl = useYext();
  const router = useRouter();
  const searchDisabled = useSelector(globalsSearchDisabled);

  if (yextUrl) {
    return <div className="c-search-bar-placeholder" />;
  }
  if (!searchConfig) return null;
  const { searchResultPageUrl } = searchConfig;

  const handleSearchSubmit = (e) => {
    const input = e.target?.value || (typeof e === 'string' && e) || '';

    if (!isEmpty(input)) {
      const query = encodeURIComponent(striptags(input));
      router.push(`${searchResultPageUrl}?q=${query}`);
    }

    return false;
  };

  if (searchDisabled) {
    return <div className="c-search-bar-form--grey-bg" />;
  } else {
    return (
      <form
        className="c-search-bar-form c-search-bar-form--grey-bg"
        onSubmit={(e) => {
          e.preventDefault();
          handleSearchSubmit(e);
        }}
        action={searchResultPageUrl}
      >
        <SearchAutocomplete
          id="homepage-search"
          handleSubmit={handleSearchSubmit}
          className="c-search-bar--homepage"
          searchPlaceholder={searchPlaceholder}
        />
      </form>
    );
  }
}

export default SearchBarHome;
