// styles
import { HiddenH1 } from './home.styles';

// types
import { HomeProps } from './home.types';

// Components
import MetaData from '../MetaData';
import Footer from 'components/Footer/Footer';
import { Slider } from 'components/ContentElements/Slider/Slider';
import { ContentArea } from 'components/ContentArea/ContentArea';
import SearchBarHome from 'components/SearchBar/SearchBarHome';

// utils
import { isEmpty } from 'utils/is-empty';

export function Home({
  page: { slider, heroTile },
  metaData,
  contentAreas: { tile },
}: Readonly<HomeProps>) {
  return (
    <>
      <MetaData {...metaData} />
      <main>
        {metaData.title && <HiddenH1>{metaData.title}</HiddenH1>}
        <Slider
          {...slider}
          sliderType="homepage"
          sliderContext="homepage"
          catalogName="pt_slider"
        />
        <SearchBarHome />
        {!isEmpty(heroTile.items) && (
          <div className="grid-container">
            <Slider
              items={heroTile.items}
              slideDuration={heroTile.slideDuration}
              sliderContext="homepage"
              sliderType="hero-tile"
            />
          </div>
        )}
        <ContentArea content={tile} contentType="tile" from="homepage" />
      </main>
      <Footer />
    </>
  );
}
